import React from 'react';
import { json, redirect } from "@remix-run/node";
import { Form, useLoaderData } from "@remix-run/react";
import { login } from "../../shopify.server";
import "../../assets/global.css"
import shopifyLogo from '../../assets/shopifyLogo.svg';
import beams from "../../assets/beams.jpg"
import FooterIndex from '../../components/_index/FooterIndex';


export const meta = () => {
  return [
    {
      title: "PricePitch | Dynamic Pricing Tool for Shopify Merchants"
    },
    {
      property: "og:title",
      content: "PricePitch | Dynamic Pricing Tool for Shopify Merchants"
    },
    {
      name: "description",
      content: "PricePitch lets Shopify merchants empower customers by allowing them to propose their prices. Set flexible pricing controls, engage buyers actively, and boost your sales."
    },
  ];
}


export const loader = async ({ request }) => {
  const url = new URL(request.url);
  if (url.searchParams.get("shop")) {
    throw redirect(`/app?${url.searchParams.toString()}`);
  }
  return json({ showForm: Boolean(login) });
};

export default function App() {
  return (
    <div className="tw-items-center tw-relative tw-flex tw-min-h-screen tw-flex-col tw-justify-center tw-overflow-hidden tw-bg-gray-50 tw-py-6 tw-sm:py-12">
      <img src={beams} alt="" className="tw-absolute tw-left-1/2 tw-top-1/2 tw-max-w-none tw--translate-x-1/2 tw--translate-y-1/2" width="1308" />
      <div className={`tw-absolute tw-inset-0 tw-bg-[url('/grid.svg')] tw-bg-center tw-[mask-image:linear-gradient(180deg,white,rgba(255,255,255,0))] sm:tw-bg-[length:8%] md:tw-bg-[length:3%]`}></div>
      <div className="tw-relative tw-bg-white tw-pb-8 tw-pt-10 tw-ring-4 tw-ring-gray-900/5 tw-max-w-4xl tw-rounded-lg tw-px-10 sm:mx-auto sm:tw-mx-2">
        <div className="tw-mx-auto tw-max-w-2xl">
          <h1 className="tw-h-6 tw-text-4xl tw-font-bold">PricePitch</h1>
          <div className="tw-divide-y tw-divide-gray-300/50 tw-flex tw-flex-col">
            <div className="tw-space-y-6 tw-py-8 tw-text-lg tw-leading-7 tw-text-gray-600">
              <p>Empower your customers to name their own price!</p>
              <div className="tw-w-full sm:tw-h-[250px] md:tw-h-[400px] tw-bg-[url('/illustration.svg')] tw-bg-no-repeat tw-bg-center tw-bg-contain"></div>
              <p>
                PricePitch allows Shopify merchants to reimagine pricing strategies by setting dynamic minimum and maximum limits and suggested price ranges for products. It automates approvals to align with your pricing preferences and actively engages customers in negotiations, turning browsers into buyers. The app facilitates seamless management of price pitches with customizable notifications and an intuitive dashboard for real-time response to customer offers.
              </p>
            </div>
            <div className="tw-pt-8 tw-text-base tw-font-semibold tw-leading-7">
                <a href="https://apps.shopify.com/pricepitch" target="_blank" rel="noreferrer">
                  <img src="/Shopify-App-Store-Badge-Final-Black.svg" alt="Shopify App Store" className="tw-text-center tw-w-80 tw-mx-auto" />
                </a>
            </div>
          </div>
        </div>
      </div>
      <FooterIndex />
    </div>
  );
}
